import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import React from 'react';
import theme from 'shared/styles/mui/theme';
import { OrganizationFolder } from '../types';

interface Props {
  data: OrganizationFolder[];
  value: number;
  onChange: (organizationId: number, ancestorFolderId: number) => void;
}

export default function ProjectListOrganizationSelect({ data, value, onChange }: Props) {
  if (!data?.length) return null;

  const handleChange = (event: SelectChangeEvent) => {
    const v = +event.target.value;
    const ancestorFolderId = data.find((item) => item.organizationId === v)?.id ?? 0;
    onChange(v, ancestorFolderId);
  };

  return (
    <Select
      value={value?.toString() ?? ''}
      onChange={handleChange}
      sx={{
        maxWidth: '13.75rem',
        maxHeight: '2.5rem',
        backgroundColor: theme.palette.background.paper,
        marginBottom: '1.5rem',
      }}
    >
      {data?.map((item) => (
        <MenuItem key={item.organizationId} value={item.organizationId}>
          {item.organizationDescription}
        </MenuItem>
      ))}
    </Select>
  );
}
