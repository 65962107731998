import {
  _ProjectListReadApiParams,
  _ProjectListReadApiResponse,
} from 'features/genaral/project/types';
import { useQuery } from 'react-query';
import api from './api';
import { keys } from './queryKeys';

export default function useProjectWithOrganizationQuery(params: _ProjectListReadApiParams) {
  return useQuery<_ProjectListReadApiResponse>(keys.projectWithOrganization(params), () =>
    api.organization.list(params),
  );
}
