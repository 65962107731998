/**
 * 기능 : 서비스 레이어에서 버전 정보를 호출하고 콘솔에 노출 합니다.
 */
export const welcomeToMeissa = () => {
  // eslint-disable-next-line @typescript-eslint/no-var-requires
  const releaseVersion = require('../../../package.json').version;
  console.log(
    '🛸　　　 　🌎　°　　🌓　•　　.°•　　　🚀 ✯\n' +
      '　　　★　*　　　　　°　　　　🛰 　°·　　   🪐\n' +
      '.　　　•　° ★　•  ☄     .　　　•　° ★\n' +
      `▁▂▃▄▅▆▇▇▆▅▄▃▁▂\n%cMEISSA ${releaseVersion}`,
    'color:#1cce69; background: #3d09df; font-size: 1rem; padding: 0.15rem 0.25rem;',
  );
};
