import useGlobalDialog from 'features/common/hooks/useGlobalDialog';
import { sortOrderTypes } from 'features/project/listPage/SortOrder';
import { FolderTreeItemType } from 'features/styleComponents/folder/FolderTreeView';
import { useAtom, useSetAtom } from 'jotai';
import routes from 'pages/routes';
import { useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { isGreen } from 'shared/common/customize';
import { createUniqueName, getUserInfoToStorage } from 'shared/common/utils';
import useDebounce from 'shared/hooks/useDebounce';
import useInfiniteScrolling from 'shared/hooks/useInfiniteScrolling';
import { nls } from 'shared/locale/language';
import { globalSnackbarAtom } from 'stores/v3/snapshotDetail/globalSnackbar';
import { PROJECT_LIST_PAGINATION_LIMIT } from '../projectFolder.const';
import useProjectWithOrganizationHierarchyQuery from '../query/useProjectWithOrganizationHierarchyQuery';
import useProjectWithOrganizationMutation from '../query/useProjectWithOrganizationMutation';
import useProjectWithOrganizationQuery from '../query/useProjectWithOrganizationQuery';
import { projectListStore } from '../store/projectListStore';
import { _ProjectListReadApiParams, ProjectPathNavigationItemType } from '../types';

/** @todo 마이그레이션을 단계적으로 진행해서 필요한 코드
 * 마이그레이션이 완료되면, getUserCategory 함수를 삭제하고, getUserInfoToStorage를 그대로 사용하도록 수정
 */
function getUserCategory() {
  const { category, isOrganizationFolderExist } = getUserInfoToStorage();
  if (!isOrganizationFolderExist) {
    return 'project_user';
  }
  return category;
}

const SORT_ORDER_ID_KEY = 'sortOrder';
export default function useProjectListViewModel() {
  const history = useHistory();
  const { showConfirm } = useGlobalDialog();
  const setAlertSnackbar = useSetAtom(globalSnackbarAtom);

  const { divRef, isOverLoadableHeight } = useInfiniteScrolling(1200);

  const [currentProjectFolderId, setCurrentProjectFolderId] = useAtom(
    projectListStore.currentProjectFolderId,
  );
  const [selectedOrganizationId, setSelectedOrganizationId] = useAtom(
    projectListStore.selectedOrganizationId,
  );

  const [projectList, setProjectList] = useState([]);
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
  const [page, setPage] = useState<number>(1);
  const storedSortOrderId = localStorage.getItem(SORT_ORDER_ID_KEY);
  const storedSortOrder = Object.values(sortOrderTypes).find((x) => x.id === storedSortOrderId);
  const [order, setOrder] = useState(storedSortOrder || sortOrderTypes.constructionDateDesc);
  const debounceKeyword = useDebounce<string>(searchKeyword, 500);

  const isSearching = Boolean((searchKeyword?.length ?? 0) > 0);
  const { data, status } = useProjectWithOrganizationQuery({
    ancestorFolderId: !isSearching ? currentProjectFolderId : false,
    name: isSearching ? debounceKeyword : '',
    limit: PROJECT_LIST_PAGINATION_LIMIT,
    order: order.apiLabel as _ProjectListReadApiParams['order'],
    page,
  });
  const { data: projectHierarchy } = useProjectWithOrganizationHierarchyQuery({
    enabled: getUserCategory() === 'product_employee' || getUserCategory() === 'organization_user',
  });
  const {
    createFolderMutation,
    updateFolderMutation,
    ungroupFolderMutation,
    deleteFolderMutation,
    moveFolderMutation,
  } = useProjectWithOrganizationMutation();

  const showCreateButton = getUserInfoToStorage().isOwner;
  const currentFolder = data?.result?.current;
  const hideFolderActions = Boolean(searchKeyword?.length) || getUserCategory() === 'project_user';
  const showOrganizationSelect =
    !Boolean(searchKeyword?.length) && getUserCategory() === 'product_employee';

  const folderList = (
    hideFolderActions || !data?.result?.childrenFolderList ? [] : data?.result?.childrenFolderList
  )?.map((x) => x.current);
  const empty =
    status === 'success' &&
    data?.result?.childrenFolderList?.length === 0 &&
    data?.result?.childrenDescendantList?.length === 0;
  const organizationList = data?.result?.organizationList;

  const [editingFolderInfo, setEditingFolderInfo] = useState<{
    open: boolean;
    id?: number;
    mode?: 'create' | 'edit';
    initialName?: string;
    duplicateNameError?: boolean;
  }>({ open: false });
  const [mode, setMode] = useState<'normal' | 'select'>('normal');

  const [movingFolderInfo, setMovingFolderInfo] = useState<{
    open: boolean;
    currentFolderId?: number;
    data?: FolderTreeItemType;
  }>({ open: false });
  const [selectedProjectIdList, setSelectedProjectIdList] = useState<number[]>([]);
  const [selectedFolderIdList, setSelectedFolderIdList] = useState<number[]>([]);

  const [rootPathItem, hiddenProjectPathNavigationItemList, visibleProjectPathNavigationItemList] =
    useMemo(() => {
      const projectPathNavigationItemList =
        data?.result?.breadcrumbFolderList?.map((x, index) => ({
          id: x.id,
          name: index === 0 ? nls.zoneProjectList() : x.name,
          isCurrent: x.id === currentFolder?.id,
          isLoading: false,
          disabled: mode === 'select',
          onClick: () => {
            if (mode === 'select') return;
            setCurrentProjectFolderId(x.id);
          },
        })) || [];

      const rootFolderNameLength = nls.zoneProjectList().length + 3;
      const startIndexOfVisibleFolderList = getStartIndexOfVisibleItemList(
        rootFolderNameLength,
        projectPathNavigationItemList,
      );
      return [
        projectPathNavigationItemList[0],
        projectPathNavigationItemList.slice(1, startIndexOfVisibleFolderList),
        projectPathNavigationItemList.slice(startIndexOfVisibleFolderList),
      ];
    }, [data, mode]);

  useEffect(() => {
    const shouldShowGreenShortcut =
      isGreen() && !showCreateButton && data?.result?.childrenDescendantList?.length == 1;
    if (shouldShowGreenShortcut) {
      if (!data || searchKeyword) {
        return;
      }
      const [targetProject] = data.result.childrenDescendantList;
      history.replace(
        routes.green.shortcut.of({
          projectId: targetProject.id,
          zoneId: targetProject.oldestZone?.id,
        }),
      );
    }
  }, [data]);

  useEffect(() => {
    if (!currentProjectFolderId && currentFolder) {
      setCurrentProjectFolderId(currentFolder.id);
    }
  }, [currentFolder]);

  useEffect(() => {
    if (page !== 1) {
      divRef.current.scrollTop = 0;
      setPage(1);
    }
  }, [searchKeyword]);

  useEffect(() => {
    if (data && page === 1) {
      setProjectList([...data?.result?.childrenDescendantList]);
    }
  }, [searchKeyword, data]);

  useEffect(() => {
    if (isOverLoadableHeight && data?.meta.hasNext) {
      setPage(page + 1);
    }
  }, [isOverLoadableHeight]);

  useEffect(() => {
    if (status !== 'success') return;
    if (page === 1) {
      setProjectList([...data.result.childrenDescendantList]);
    } else {
      setProjectList((prev) => [...prev, ...data.result.childrenDescendantList]);
    }
  }, [page, status]);

  function onChangeMode(x: 'normal' | 'select') {
    setMode(x);
    setSelectedProjectIdList([]);
    setSelectedFolderIdList([]);
  }

  function onClickFolder(folderId: number) {
    if (mode === 'normal') {
      setCurrentProjectFolderId(folderId);
    } else {
      setSelectedFolderIdList((prev) =>
        prev.includes(folderId) ? prev.filter((x) => x !== folderId) : [...prev, folderId],
      );
    }
  }

  function onClickProject(projectId: number) {
    if (mode === 'normal') {
      const project = data?.result?.childrenDescendantList?.find((x) => x.id === projectId);
      const href = isGreen()
        ? routes.green.shortcut.of({ projectId: project.id, zoneId: project.oldestZone?.id })
        : routes.snapshot.list.of({ projectId: project.id, zoneId: project.oldestZone?.id });

      history.push(href, isGreen() ? 'projectListPage' : null);
    } else {
      setSelectedProjectIdList((prev) =>
        prev.includes(projectId) ? prev.filter((x) => x !== projectId) : [...prev, projectId],
      );
    }
  }
  function isSelectedProject(projectId: number) {
    return mode === 'select' && selectedProjectIdList.includes(projectId);
  }
  function isSelectedFolder(folderId: number) {
    return mode === 'select' && selectedFolderIdList.includes(folderId);
  }

  function onClickNewFolder() {
    setEditingFolderInfo({
      open: true,
      mode: 'create',
      initialName: createUniqueName(
        nls.basicNewFolder(),
        folderList?.map((x) => x.name),
      ),
      duplicateNameError: false,
    });
  }

  function onClickNewProject() {
    history.push(routes.project.new.path);
  }

  function onClickEditFolder(folderId: number) {
    const folder = folderList?.find((x) => x.id === folderId);
    if (!folder) return;

    setEditingFolderInfo({
      open: true,
      mode: 'edit',
      initialName: folder.name,
      id: folder.id,
      duplicateNameError: false,
    });
  }

  function onClickMoveButton() {
    if (!currentFolder || !projectHierarchy) return;
    setMovingFolderInfo({
      open: true,
      currentFolderId: currentFolder?.id,
      data: projectHierarchy,
    });
  }

  function onCloseMovingFolderModal() {
    setMovingFolderInfo({ open: false });
  }

  function onCloseEditingFolderModal() {
    setEditingFolderInfo({ open: false });
  }

  async function onConfirmEditingFolder(name: string) {
    if (!name || !currentFolder) return;

    if (editingFolderInfo.mode === 'create') {
      await createFolderMutation.mutateAsync(
        {
          organizationId: currentFolder.organizationId,
          name,
          parentFolderId: currentFolder.id,
        },
        {
          onError: () => {
            setEditingFolderInfo((prev) => ({ ...prev, duplicateNameError: true }));
          },
        },
      );
    } else if (editingFolderInfo.mode === 'edit') {
      await updateFolderMutation.mutateAsync(
        { id: editingFolderInfo.id, name },
        {
          onError: () => {
            setEditingFolderInfo((prev) => ({ ...prev, duplicateNameError: true }));
          },
        },
      );
    }
    onCloseEditingFolderModal();
  }

  function onClickUngroupFolder(folderId: number) {
    const folderName = folderList?.find((x) => x.id === folderId)?.name;
    showConfirm({
      title: nls.projectFolderUngroupMenuTitle(),
      content: nls.projectFolderUngroupModalContentMessage(folderName),
      primaryButtonProps: {
        title: nls.basicUngroup(),
        onClick: () => {
          ungroupFolderMutation.mutate({ folderId });
        },
        color: 'secondary',
      },
    });
  }

  function onClickDeleteFolder(folderId: number) {
    const folderName = folderList?.find((x) => x.id === folderId)?.name;
    showConfirm({
      title: nls.basicDeleteFolder(),
      content: nls.projectFolderDeleteModalContentMessage(folderName),
      primaryButtonProps: {
        title: nls.delete(),
        onClick: () => {
          deleteFolderMutation.mutate({ folderId });
        },
        color: 'secondary',
      },
    });
  }

  async function onClickMovingFolderModalConfirm(
    destinationFolderId: number,
    destinationFolderName: string,
  ) {
    await moveFolderMutation.mutateAsync({
      destinationFolderId,
      descendantFolderIdList: selectedFolderIdList,
      descendantIdList: selectedProjectIdList,
      currentFolderId: movingFolderInfo.currentFolderId,
    });
    setAlertSnackbar((prev) => ({
      ...prev,
      open: true,
      message: nls.projectFolderMoveSuccessMessage(destinationFolderName),
      anchorOrigin: 'topCenter',
      type: 'success',
    }));
    onChangeMode('normal');
    onCloseMovingFolderModal();
  }

  function onChangeOrganizationId(organizationId: number, ancestorFolderId: number) {
    setPage(1);
    divRef.current.scrollTop = 0;
    setCurrentProjectFolderId(ancestorFolderId);
    setSelectedOrganizationId(organizationId);
  }

  function onClickProjectOnGNB() {
    if (getUserCategory() === 'project_user') return;
    const rootFolderId = data?.result?.breadcrumbFolderList?.[0]?.id;
    if (rootFolderId) {
      setCurrentProjectFolderId(rootFolderId);
    }
  }

  const loading = status === 'loading' && page === 1;

  return {
    divRef,
    searchKeyword,
    setSearchKeyword,
    sortOrder: order,
    setSortOrder: setOrder,

    showOrganizationSelect,
    showCreateButton,
    hideFolderActions,
    empty,
    loading,
    hiddenProjectPathNavigationItemList,
    visibleProjectPathNavigationItemList,
    rootPathItem,
    folderList,
    projectList,
    organizationList,
    organizationId: selectedOrganizationId,
    onChangeOrganizationId,

    mode,
    disabledChangeMode: empty,
    disabledMoveButton: selectedFolderIdList.length === 0 && selectedProjectIdList.length === 0,
    selectedFolderIdList,
    onChangeMode,
    onClickFolder,
    isSelectedFolder,
    onClickProject,
    isSelectedProject,

    editingFolderInfo,
    onClickNewFolder,
    onClickNewProject,
    onClickEditFolder,
    onClickUngroupFolder,
    onClickDeleteFolder,
    onConfirmEditingFolder,
    onCloseEditingFolderModal,

    movingFolderInfo,
    onClickMoveButton,
    onCloseMovingFolderModal,
    onClickMovingFolderModalConfirm,

    onClickProjectOnGNB,
  };
}

const VISIBLE_PATH_NAVIGATION_MAX_LENGTH = 25;
const FOLDER_HIERARCHY_INDICATOR_LENGTH = 3;
function getStartIndexOfVisibleItemList(offset: number, itemList: ProjectPathNavigationItemType[]) {
  let index = Math.max(itemList.length - 1, 1);
  while (
    index > 1 &&
    calculateVisibleFolderAreaLength(itemList.slice(index)) <
      VISIBLE_PATH_NAVIGATION_MAX_LENGTH - (offset || 0)
  ) {
    index--;
  }
  return index;
}

function calculateVisibleFolderAreaLength(lst: ProjectPathNavigationItemType[]) {
  if (lst.length === 0) {
    return 0;
  } else if (lst.length === 1) {
    return lst[0].name.length;
  } else {
    return (
      lst.reduce((acc, folder) => acc + folder.name.length, 0) +
      (lst.length - 1) * FOLDER_HIERARCHY_INDICATOR_LENGTH
    );
  }
}
