import StylesProvider from '@material-ui/styles/StylesProvider';
import ThemeProvider from '@material-ui/styles/ThemeProvider';
import { CssBaseline, ThemeProvider as MuiV5ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import GlobalStyle from 'GlobalStyle';
import AppUsageInducementModal from 'features/common/AppUsageInducementModal';
import ErrorPage from 'features/common/ErrorPage';
import GlobalDialog from 'features/common/GlobalDialog';
import LazyFallbackComponent from 'features/common/LazyFallbackComponent';
import { useRefreshToken } from 'features/common/hooks';
import useHideChannelTalkButton from 'features/common/hooks/useHideChannelTalkButton';
import CctvPlayerPage from 'features/snapshot/cctvPlayerPage/CctvPlayerPage';
import IssuePolygonComparedPage from 'features/snapshot/issuePolygonComparedPage/IssuePolygonComparedPage';
import * as Pages from 'pages';
import React, { lazy, Suspense } from 'react';
import { hot } from 'react-hot-loader/root';
import { Redirect, Route, RouteProps, Switch } from 'react-router-dom';
import { isJapan } from 'shared/common/customize';
import { useCustomTitle } from 'shared/common/customize/design';
import { isLogin } from 'shared/common/utils';
import { AlertModal } from 'shared/component/modal/AlertModal';
import { getLanguage } from 'shared/locale/language';
import useReactQueryDefaultOptions from 'shared/query/error/useReactQueryDefaultOptions';
import theme from 'shared/styles/mui/theme';
import { globalTheme } from 'shared/styles/muiV5/globalTheme';
import './fonts.css';
import routes from './pages/routes';

/**
 * @todo 화면 갱신으로 인한 이슈 발생 추후 개선 필요함. (고민을 조금 더 해봐야 할거 같음)
 */
// import {
//   PolygonAnnotationShow,
//   PolygonAnnotationEdit,
//   PolylineAnnotation,
//   PointAnnotation,
//   ChainLineAnnotation,
//   DrawingVectorShow,
//   SnapshotShowByMapType,
//   HazardAreaNew,
//   HazardAreaShow,
//   IssueShow,
//   IssueDeprecatedShow,
//   ChainList,
//   EarthworkDetailTable,
//   EarthworkSummaryTable,
//   GreenShow,
//   CctvShow,
//   FileSharing,
//   NewPointSnapshotDetail,
//   NewPolylineSnapshotDetail,
//   NewPolygonSnapshotDetail,
// } from 'pages';

setLanguage();

const App = () => {
  useRefreshToken();
  useCustomTitle();
  useReactQueryDefaultOptions();
  useHideChannelTalkButton(isJapan());

  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <ThemeProvider theme={theme}>
        <MuiV5ThemeProvider theme={globalTheme}>
          <CssBaseline />
          <AppUsageInducementModal />
          <AlertModal />
          <Sentry.ErrorBoundary fallback={<ErrorPage />}>
            <Suspense fallback={<LazyFallbackComponent />}>
              <Switch>
                <Redirect exact from={routes.root.path} to={routes.project.index.path} />
                <Route exact path={routes.login.path} component={Pages.Login} />
                <Route path={routes.external.path} component={Pages.External} />
                <Route path={routes.mobile.path} component={Pages.Mobile} />
                <Route path={routes.guard.path} component={Pages.Guard} />
                <PrivateRoute
                  exact
                  path={routes.project.member.info.path}
                  component={Pages.ProjectMember}
                />
                <PrivateRoute
                  exact
                  path={[routes.project.new.path, routes.project.edit.path]}
                  component={Pages.ProjectNew}
                />
                <PrivateRoute exact path={routes.project.info.path} component={Pages.ProjectInfo} />
                <PrivateRoute
                  exact
                  path={routes.project.index.path}
                  component={Pages.ProjectList}
                />
                <PrivateRoute
                  exact
                  path={routes.project.archive.path}
                  component={Pages.FileArchive}
                />
                <PrivateRoute
                  exact
                  path={routes.project.laborerHistory.path}
                  component={Pages.LaborerHistory}
                />
                <PrivateRoute
                  exact
                  path={routes.project.vehicleHistory.path}
                  component={Pages.VehicleHistory}
                />
                <Redirect exact from={routes.project.show.path} to={routes.project.info.path} />
                <PrivateRoute
                  path={routes.drawingConfig.show.path}
                  component={Pages.DrawingConfig}
                />
                <PrivateRoute
                  exact
                  path={routes.snapshot.entryNew.path}
                  component={Pages.EntryNew}
                />
                <PrivateRoute exact path={routes.snapshot.new.path} component={Pages.SnapshotNew} />
                <PrivateRoute
                  exact
                  path={routes.snapshot.edit.path}
                  component={Pages.SnapshotEdit}
                />
                <PrivateRoute path={routes.snapshot.print.path} component={Pages.SnapshotPrint} />
                <PrivateRoute
                  path={routes.snapshot.compareTwo.path}
                  component={Pages.SnapshotListCompareTwo}
                />
                <PrivateRoute
                  path={routes.snapshot.compareFour.path}
                  component={Pages.SnapshotListCompareFour}
                />
                <PrivateRoute
                  path={routes.snapshot.slider.path}
                  component={Pages.SnapshotListSlider}
                />
                <PrivateRoute
                  path={routes.snapshot.compareGreenByGreen.path}
                  component={Pages.GreenByGreen}
                />
                <PrivateRoute
                  path={routes.snapshot.compareGreenByDate.path}
                  component={Pages.GreenByDate}
                />
                <PrivateRoute
                  path={routes.snapshot.superResolutionCompareSlider.path}
                  component={Pages.SuperResolutionCompareSlider}
                />
                <PrivateRoute
                  path={routes.snapshot.superResolutionCompareTwo.path}
                  component={Pages.SuperResolutionCompareTwo}
                />
                <PrivateRoute
                  path={routes.snapshot.compareChangeDetection.path}
                  component={Pages.ChangeDetection}
                />
                <PrivateRoute path={routes.snapshot.gcpEditor.path} component={Pages.GcpEditor} />
                <PrivateRoute path={routes.snapshot.dtmEditor.path} component={Pages.DtmEditor} />
                <PrivateRoute
                  path={routes.drawingVector.edit.path}
                  component={Pages.DrawingVectorEdit}
                />
                <PrivateRoute path={routes.snapshot.cctv.path} component={CctvPlayerPage} />
                <PrivateRoute
                  path={routes.issue.polygon.list.path}
                  component={IssuePolygonComparedPage}
                />
                <PrivateRoute
                  exact
                  path={routes.snapshot.report.path}
                  component={Pages.EnginReport}
                />
                <PrivateRoute
                  exact
                  path={[
                    routes.drawingVector.show.path,
                    routes.snapshot.show.path,
                    routes.hazardArea.new.path,
                    routes.hazardArea.show.path,
                    routes.issue.show.path,
                    routes.issue.deprecatedShow.path,
                    routes.earthwork.detailTable.path,
                    routes.earthwork.summaryTable.path,
                    routes.green.show.path,
                    routes.cctv.show.path,
                    routes.snapshot.fileSharing.path,

                    routes.newPoint.show.path,
                    routes.newPolyline.show.path,
                    routes.newPolygon.show.path,
                    routes.crossingLineSet.show.path,
                    routes.crossingLine.show.path,
                  ]}
                  component={SnapshotDetailsChunkContainer}
                />
                <Redirect
                  from={routes.snapshot.showBase.path}
                  to={`${routes.snapshot.showBase.path}/2d`}
                />
                <PrivateRoute exact path={routes.zone.map.path} component={Pages.ZoneMap} />
                <PrivateRoute
                  exact
                  path={[routes.snapshot.list.path, '/projects/:projectId/zones']}
                  component={Pages.ZoneList}
                />
                <Redirect
                  exact
                  from="/projects/:projectId/zones/:zoneId"
                  to={routes.snapshot.list.path}
                />
                <PrivateRoute
                  exact
                  path={routes.building.show.path}
                  component={Pages.BuildingDetailList}
                />
                <PrivateRoute
                  exact
                  path={routes.miniMap.show.path}
                  component={Pages.MiniMapSetting}
                />
                <PrivateRoute
                  exact
                  path={routes.building.compare.path}
                  component={Pages.WalkthroughCompare}
                />
                <Route path={routes.user.findPw.path} component={Pages.FindPw} />
                <PrivateRoute
                  path={routes.landDisplacement.show.path}
                  component={Pages.LandDisplacement}
                />
                <Route path={routes.user.join.path} component={Pages.Join} />
                <PrivateRoute path={routes.user.info.show.path} component={Pages.UserInfo} />
                <PrivateRoute path={routes.user.info.edit.path} component={Pages.UserEdit} />
                <Route exact path={routes.readonly.path} component={Pages.Watch} />
                <PrivateRoute
                  exact
                  path={routes.maintenance.manipulate.path}
                  component={Pages.ProjectLogManipulate}
                />
                <PrivateRoute
                  exact
                  path={routes.maintenance.date.path}
                  component={Pages.ProjectLogManipulateByDate}
                />
                <PrivateRoute
                  exact
                  path={routes.maintenance.list.path}
                  component={Pages.ProjectLogManipulateList}
                />
                <PrivateRoute
                  exact
                  path={routes.photoBox.media.path}
                  component={Pages.PhotoBoxMedia}
                />
                <PrivateRoute path={routes.photoBox.photo.path} component={Pages.PhotoBoxPhoto} />
                <PrivateRoute path={routes.photoBox.video.path} component={Pages.PhotoBoxVideo} />
                <PrivateRoute
                  exact
                  path={routes.photoBox.album.path}
                  component={Pages.PhotoBoxAlbum}
                />
                <PrivateRoute
                  exact
                  path={routes.photoBox.albumDetail.path}
                  component={Pages.PhotoBoxAlbumDetail}
                />
                <PrivateRoute
                  exact
                  path={routes.green.shortcut.path}
                  component={Pages.GreenShortcut}
                />
                <Redirect to={routes.project.index.path} />
              </Switch>
            </Suspense>
          </Sentry.ErrorBoundary>
        </MuiV5ThemeProvider>
        <GlobalDialog />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default hot(App);

// MARK: Private Helper
function setLanguage(lang: string = getLanguage()) {
  document.body.setAttribute('class', lang);
  document.body.setAttribute('lang', lang);
}

const PrivateRoute = ({ ...rest }: RouteProps) => {
  return isLogin() ? (
    <Route {...rest} />
  ) : (
    <Redirect to={{ pathname: routes.login.path, state: { from: rest.location } }} />
  );
};

const SnapshotDetailsChunkContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "SnapshotDetailsContainer" */ 'features/snapshot/detailsPage/SnapshotDetailsContainer'
    ),
);
