import React, { lazy } from 'react';
const ExternalPage = lazy(
  () => import(/* webpackChunkName: "externalPage" */ 'features/external/ExternalPage'),
);

function Extenal() {
  return <ExternalPage />;
}

export default Extenal;
