import { atom } from 'jotai';

export * from './annotation/point';
export * from './annotation/polygon';
export * from './annotation/polyline';
export * from './cadastral';
export * from './cctv';
export * from './dem';
export * from './drawing';
export * from './dsm';
export * from './etc';
export * from './hazardArea';
export * from './issue';
export * from './laborer';
export * from './ndvi';
export * from './objectDetection';
export * from './panorama';
export * from './planLevel';
export * from './segmentation';
export * from './stratum';
export * from './superResolution';
export * from './vehicle';

export const orthophotoAtom = {
  checked: atom(true),
};

export const gcpAtom = {
  checked: atom(false),
};

export const aerialImage = {
  checked: atom(false),
};
