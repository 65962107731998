import AddIcon from '@mui/icons-material/Add';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { Button } from '@mui/material';
import { ContainedButton } from 'features/common/button/ContainedButton';

import React from 'react';
import { sendProjectFolderBtn } from 'shared/ga/actions';
import { nls } from 'shared/locale/language';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';

interface Props {
  showCreateButton: boolean;
  hideFolderActions: boolean;
  mode: 'select' | 'normal';
  disabledChangeMode?: boolean;
  disabledMoveButton?: boolean;
  onChangeMode: (mode: 'select' | 'normal') => void;
  onClickMove: () => void;
  onClickNewFolder: () => void;
  onClickNewProject: () => void;
}

export default function ProjectListActionGroup({
  showCreateButton,
  hideFolderActions,
  mode,
  disabledChangeMode,
  disabledMoveButton,
  onChangeMode,
  onClickMove,
  onClickNewFolder,
  onClickNewProject,
}: Props) {
  if (hideFolderActions && !showCreateButton) {
    return null;
  }
  if (hideFolderActions && showCreateButton) {
    return (
      <StyledButton
        variant="contained"
        color="secondary"
        size="large"
        startIcon={<AddIcon />}
        onClick={onClickNewProject}
      >
        {nls.projectCreateButtonTitle()}
      </StyledButton>
    );
  }
  return (
    <>
      <ButtonGroup visible={mode === 'normal'}>
        <LegacyDefaultButton
          color="default"
          size="large"
          startIcon={<CheckBoxIcon />}
          onClick={() => onChangeMode('select')}
          disabled={disabledChangeMode}
        >
          {nls.select()}
        </LegacyDefaultButton>
        <StyledButton
          variant="outlined"
          color="secondary"
          size="large"
          startIcon={<CreateNewFolderIcon />}
          onClick={() => {
            onClickNewFolder();
            sendProjectFolderBtn();
          }}
        >
          {nls.basicNewFolder()}
        </StyledButton>
        <StyledButton
          variant="contained"
          color="secondary"
          size="large"
          startIcon={<AddIcon />}
          onClick={onClickNewProject}
        >
          {nls.projectCreateButtonTitle()}
        </StyledButton>
      </ButtonGroup>
      <ButtonGroup visible={mode === 'select'}>
        <LegacyDefaultButton color="default" size="large" onClick={() => onChangeMode('normal')}>
          {nls.cancel()}
        </LegacyDefaultButton>
        <StyledButton
          variant="contained"
          color="primary"
          size="large"
          startIcon={<DriveFileMoveIcon />}
          onClick={onClickMove}
          disabled={disabledMoveButton}
        >
          {nls.basicMove()}
        </StyledButton>
      </ButtonGroup>
    </>
  );
}

const ButtonGroup = styled.div<{ visible: boolean }>`
  ${({ visible }) => (!visible ? 'display: none' : 'display: flex')};
  justify-content: flex-end;
  gap: 0.75rem;
  transition: all 0.3s ease;
  min-width: 26rem;
`;

const LegacyDefaultButton = styled(ContainedButton)`
  height: 2.5rem;
  padding: 0 1.25rem;
  svg {
    color: ${theme.palette.text.secondary};
  }
`;

const StyledButton = styled(Button)`
  height: 2.5rem;
  padding: 0 1.25rem;
  font-weight: 600;
`;
