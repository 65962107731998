import { CircularProgress, Divider } from '@mui/material';
import { useDefaultEventBlocker } from 'features/common/hooks';
import NavigationNoticeLayout from 'features/common/layout/NavigationNoticeLayout';
import Snackbar from 'features/styleComponents/snackbar/Snackbar';
import React from 'react';
import { NORMAL_SCREEN_MAX_WIDTH, SMALL_SCREEN_MAX_WIDTH } from 'shared/common/policies/screen';
import { navigationBarWidth } from 'shared/common/policies/sizes';
import { getCommonLocaleDate } from 'shared/common/utils/date';
import theme from 'shared/styles/mui/theme';
import styled from 'styled-components';
import EditingProjectFolderModal from './EditingProjectFolderModal';
import ProjectFolderItem from './ProjectFolderItem';
import ProjectFolderMoveModal from './ProjectFolderMoveModal';
import ProjectItem from './ProjectItem';
import ProjectListEmptyView from './ProjectListEmptyView';
import ProjectListFolderPathArea from './ProjectListFolderPathArea';
import ProjectListOrganizationSelect from './ProjectListOrganizationSelect';
import ProjectListSearchAndSortArea from './ProjectListSearchAndSortArea';
import useProjectListViewModel from './useProjectListViewModel';

export default function ProjectList() {
  useDefaultEventBlocker(window, 'contextmenu');

  const {
    divRef,
    sortOrder,
    setSortOrder,
    searchKeyword,
    setSearchKeyword,

    loading,
    empty,
    rootPathItem,
    hiddenProjectPathNavigationItemList,
    visibleProjectPathNavigationItemList,
    folderList,
    projectList,
    organizationId,
    organizationList,
    onChangeOrganizationId,
    selectedFolderIdList,

    showOrganizationSelect,
    showCreateButton,
    hideFolderActions,
    mode,
    onChangeMode,
    onClickFolder,
    onClickProject,
    isSelectedFolder,
    isSelectedProject,
    disabledChangeMode,
    disabledMoveButton,

    editingFolderInfo,
    onClickNewFolder,
    onClickNewProject,
    onClickEditFolder,
    onClickUngroupFolder,
    onClickDeleteFolder,
    onConfirmEditingFolder,
    onCloseEditingFolderModal,

    movingFolderInfo,
    onClickMoveButton,
    onCloseMovingFolderModal,
    onClickMovingFolderModalConfirm,

    onClickProjectOnGNB,
  } = useProjectListViewModel();

  const showLoading = loading;
  const showEmpty = !loading && empty;
  const showContent = !loading && !empty;
  return (
    <Layout>
      <Container ref={divRef}>
        <TopArea>
          <ProjectListSearchAndSortArea
            hideFolderActions={hideFolderActions}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            sortOrder={sortOrder}
            setSortOrder={setSortOrder}
          />
          <ProjectListFolderPathArea
            mode={mode}
            showCreateButton={showCreateButton}
            hideFolderActions={hideFolderActions}
            rootPathItem={rootPathItem}
            hiddenFolderList={hiddenProjectPathNavigationItemList}
            visibleFolderList={visibleProjectPathNavigationItemList}
            disabledChangeMode={disabledChangeMode}
            disabledMoveButton={disabledMoveButton}
            onChangeMode={onChangeMode}
            onClickMove={onClickMoveButton}
            onClickNewFolder={onClickNewFolder}
            onClickNewProject={onClickNewProject}
          />
          {showOrganizationSelect && (
            <ProjectListOrganizationSelect
              data={organizationList}
              value={organizationId}
              onChange={onChangeOrganizationId}
            />
          )}
        </TopArea>
        <div>
          <Divider />
          {showLoading && (
            <LoadingView>
              <CircularProgress size={40} />
            </LoadingView>
          )}
          {showEmpty && <ProjectListEmptyView />}
          {showContent && (
            <ContentArea>
              {folderList?.length > 0 && (
                <ItemListWrap>
                  {folderList?.map((folder) => (
                    <ProjectFolderItem
                      key={folder.id}
                      name={folder.name}
                      numberOfProjects={folder.totalDescendantCount}
                      empty={folder.totalDescendantCount === 0}
                      selected={isSelectedFolder(folder.id)}
                      onClick={() => onClickFolder(folder.id)}
                      onClickEdit={() => onClickEditFolder(folder.id)}
                      onClickUngroup={() => onClickUngroupFolder(folder.id)}
                      onClickDelete={() => onClickDeleteFolder(folder.id)}
                    />
                  ))}
                </ItemListWrap>
              )}
              <ItemListWrap>
                {projectList?.map((project) => (
                  <ProjectItem
                    key={project.id}
                    id={project.id}
                    name={project.name}
                    thumbnailImg={project.thumbnailImg}
                    numberOfSnapshots={project.processedSnapshotCount}
                    latestSnapshotDate={getCommonLocaleDate(project.latestTakeDate)}
                    description={project.description}
                    selected={isSelectedProject(project.id)}
                    done={project.state}
                    onClick={() => onClickProject(project.id)}
                  />
                ))}
              </ItemListWrap>
            </ContentArea>
          )}
        </div>
      </Container>
      <EditingProjectFolderModal
        onClose={onCloseEditingFolderModal}
        onConfirm={onConfirmEditingFolder}
        {...editingFolderInfo}
      />
      <ProjectFolderMoveModal
        open={movingFolderInfo.open}
        data={movingFolderInfo.data}
        currentFolderId={movingFolderInfo.currentFolderId}
        selectedFolderIdList={selectedFolderIdList}
        close={onCloseMovingFolderModal}
        submit={onClickMovingFolderModalConfirm}
      />
      <NavigationNoticeLayout fixed onProjectClick={onClickProjectOnGNB} />
      <Snackbar />
    </Layout>
  );
}

const Layout = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

const Container = styled.div`
  width: 100%;
  height: 100%;
  padding-left: ${navigationBarWidth};
  overflow: scroll;
  position: relative;
  > * {
    padding: 0 4.75rem;
  }
`;

const TopArea = styled.div`
  position: sticky;
  left: 0;
  top: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 1000;
  background-color: ${theme.palette.background.default};
`;

const ContentArea = styled.div`
  display: flex;
  flex-direction: column;
  padding: 2.5rem 0;
  gap: 2.5rem;
`;

const LoadingView = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 20rem);
`;

const ItemListWrap = styled.div`
  display: inline-grid;
  width: 100%;
  gap: 2.5rem 3rem;
  grid-template-columns: repeat(3, 1fr);

  @media (max-width: ${NORMAL_SCREEN_MAX_WIDTH}) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${SMALL_SCREEN_MAX_WIDTH}) {
    grid-template-columns: 1fr;
  }
`;
